.how{
    display: flex;
    justify-content: center;
}

.list-of-items ul{
    list-style-type: none;
}
.list-of-items li{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
}
.list-of-items img{
    width: 7.5rem;
    height: 7.5rem;
    margin-left: -5rem;
}

.list-item{
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.list-item h3{
    font-size: 2.5rem;

}




@media screen and (max-width: 992px) {

    .list-of-items img{
        display: flex;
        justify-content: center;
        margin-left: 0;
    }

    .list-item {
        align-items: center;
        padding: 0 2rem;
    }

    .list-item h3{
        font-size: 2rem;
        padding: 1rem 0;
    
    }
}