.Footer{
    background-color: white;
}

.footer-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 2rem 0 .5rem 0;
}

.footer-content .link a{
    color: var(--primary-color);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;

}

@media screen and (max-width: 576px) {
    .footer-content .link a{
        font-size: 1rem;
    
    }
}