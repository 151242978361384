.Cta{
    padding: 5rem 0;
}

.cta{
    display: flex;
    justify-content: center;
}

.cta-content{
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: auto;
    padding: 4rem 2rem;
    border: 5px solid white;
    border-radius: 7.5px;
}

.cta-content h2{
    text-transform: capitalize;
    font-size: 2.5rem;
}


@media screen and (max-width: 992px) {


    .cta-content{
        justify-content: center;
        flex-direction: column;
        padding: 2rem 2rem;
        border: 4px solid white;
    }
    .cta-content h2{
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }

}

@media screen and (max-width: 576px) {
    .cta-content{
        padding: 2rem 1.75rem;
    }
    .cta-content h2{
        text-align: center;
        font-size: 2rem;
    }
    .cta-content button{
        padding: .75rem 0;

    }
}