.confirmPopUp{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    width: 100vw;
    height: 100vh;
    z-index: 100;

}

.confirmPopUp-text{
    padding: 2.5rem 0;
}

@media screen and (max-width:992px) {
    .confirmPopUp-text{
        text-align: center;
    }
}