.lp-header{
    position: relative;
    width: 100%;
    height: 60rem;
}


.bg-logo{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: .25;
}

.bg-strokes{
    position: absolute;
    bottom: 25%;
    left: 0;
    z-index: -1;
}

.bg-logo img{
    width: 32rem;
}


.head-title{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 50%;
    padding: 0 20% 0 35%;
}

.head-qoute{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40%;
    padding: 0 35% 0 20%;
}


.head-title h1{
    position: relative;
    width: 100%;
    font-size: 2.75rem;
}


.head-title h1::before{
    content: "...";
    position: absolute;
    font-size: 5rem;
    color: var(--secondary-color2);
    top: -5rem;
    left: -2rem;
}

.head-qoute p{
    position: relative;
    font-weight: 200;
}

.head-qoute p::before{
    content: '';
    position: absolute;
    color: white;
    top: -3rem;
    left: -3rem;
    width: 3rem; 
    height: 3rem; 
    background-image: url('../img/bracket.svg'); 
    background-size: cover; /* Adjust size */
    background-repeat: no-repeat;
    background-position: center;
}

.head-cta{
    height: 10%;
}

.head-cta h2{
    font-size: 2.75rem;
    word-spacing: .25rem;
}


@media screen and (max-width: 992px) {
    .lp-header{
        height: 50rem;
    }
    .bg-logo{
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .head-title{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .head-qoute{
        align-items: center;
        height: 25%;  
    }
    .head-cta {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25%;
    }
}



@media screen and  (min-width: 576px) and  (max-width: 992px) {
    .head-title h1{
        width: 70%;
        font-size: 2.5rem;
        padding: 0 2.5rem;
    }

}

@media screen and (max-width: 576px) {
    .head-title h1{
        font-size: 2rem;
        padding: 0 2rem;
    }

    .head-qoute{
        padding: 0 2rem;
    }

    .head-title h1::before{
        font-size: 2.5rem;
        left: 0;
        top: -2.5rem;
    }

    .head-qoute p::before{
        top: -2rem;
        left: -2rem;
        width: 2rem;
        height: 2rem;
    }
    .head-cta h2{
        font-size: 2rem;
    }

}