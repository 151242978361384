.Contact{
    background-color: white;
}

.contact{
    display: flex;
    color: var(--primary-color);
    padding: 5rem 0;
}

.contact-item{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    height: auto;
}

.contact-item .general{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

.contact-item .socials{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.contact-item li{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 2rem;
    font-style: normal;
    padding: 1rem 1rem;
}

.contact-item li a{
    text-decoration: none;
    color: var(--primary-color);
}


.contact strong{
    padding-right: 1rem;
}

.contact-item img{
    width: 12.5rem;
    height: auto;
}

@media screen and (max-width: 992px) {
    .contact{
            flex-direction: column;
            padding: 0;
    }

}

@media screen and (max-width: 576px) {

    .contact-item li{
        font-size: 1.5rem;

    }

    .contact-item img{
        width: 7rem;
        height: auto;
    }

}