@import url("https://fonts.googleapis.com/css2?family=Fredoka:wdth,wght@75..125,300..700&display=swap");

:root {
  --primary-color: #468275;
  --secondary-color: #59b2a5;
  --secondary-color2-opacity: rgb(89, 178, 165, 0.9);
  --font-size: 16px;
  --padding: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Fredoka", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  color: white;
  background-color: var(--primary-color);
}

.cstm-container {
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}



h1 {
  font-size: 2.5rem;
}

.subtitle h2 {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  font-size: 2.5rem;
}

.subtitle-dark {
  color: var(--primary-color);
}

p {
  font-weight: 200;
}


button {
  font-family: "Fredoka", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: 5px solid white;
  border-radius: 7.5px;
  padding: 1.25rem 5rem;
}

.mt-1{
    margin-top: 1rem;
}

.mt-2{
    margin-top: 2rem;
}

.w-100 {
  width: calc(100% - 2rem);
  margin-left: 1rem;
}
.input-50 {
    width: 50% !important;
  }

.input-75 {
    width: 75% !important;
  }

.input-100 {
    width: 100% !important;
  }

.input-150 {
  width: 150% !important;
}

.input-200 {
  width: 200% !important;
}


@media screen and (max-width:992px) {
    .subtitle h2 {
        font-size: 2rem;
      }

      button {
        border: 4px solid white;
      }
    .input-200 , .input-150 , .input-100, .input-75, .input-50{
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .cstm-container {
      padding: 0 10px;
    }
  }

@media screen and (max-width: 576px) {
    button {
      padding: 1rem 0;
    }
  }