.navbar{
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 1.5rem 0;
}

.navbar-mobile{
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--secondary-color2-opacity);
    transform: translateY(-200%);
    transition: all .25s ease;
    z-index: 10;
}


.navbar-mobile ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    width: 100%;
    height: 100%;
}

.navbar-mobile li{
    padding: 1rem 0;
}

.navbar-mobile li a{
    font-size: 1.5rem;
    font-weight: 800;
    color: white;
    text-decoration: none;
}
.navbar-menu ul {
  list-style: none;
  display: flex;
  padding-left: 0 ;
}

.navbar-menu ul li {
  margin-right: 50px;
}

.navbar-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.navbar-toggle {
    display: none;
    z-index: 11;
}

.navbar-ham{
    cursor: pointer;
}

.navbar-ham .bar {
    width: 50px;
    height: 6px;
    border-radius: 3px;
    background-color: white;
    margin: 10px 0;

  }

@media screen and (max-width: 992px) {
    
    .navbar-toggle{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .navbar-menu{
        display: none;
    }

    .navbar-mobile.active{
        visibility: visible;
        transform: translateY(0);
        transition: all .25s ease-in;
    }

}