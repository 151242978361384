.Form {
    background-color: white;
  }
  
  .form {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0;
  }
  
  form {
    width: 70%;
    padding: 1rem 1rem 2rem 1rem;
    background-color: var(--secondary-color);
    border-radius: 10px;
  }
  form h3{
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2rem;
    padding: 1rem 1rem ;
  }
  
  .group-input {
    display: flex;
    width: 100%;
    padding: 1rem 0;
  }

  .group-input-input{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 1rem;
  }


  input {
    font-family: "Fredoka", sans-serif;
    width: 100%;
    font-size: 1.25rem;
    padding: 1.5rem 1rem;
    border: 5px solid transparent;
    border-radius: 5px;
    box-sizing: border-box; 
  }

  input:focus{
    border-radius: 5px;
    border: 5px solid rgb(70, 130, 117, 0.8);
    outline: none;
  }

.PhoneInputInput{
    width: 100%;
}

.PhoneInputCountry{
    margin: 0 1rem;
}


.checkbox {
    display: flex;
    align-items: center;
    height: auto;
    margin: 2rem 1rem;
}

.form-check-input{
    border: 50px solid white;
}

input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid white;
    background-color: transparent;
    transition: all 0.25s ease;
}


input[type="date"] {
  color: gray;
}


.checkbox-text {
    font-size: 1rem;
    color: white;
    margin-left: 0.5rem;
}

.error-message{
  font-style: italic;
  position: absolute;
  bottom: -1.5rem;
  margin-top: .5rem;
  color: white;
}



@media screen and (max-width: 992px) {
    .group-input{
        flex-direction: column;
        padding: 0;
    }
    input{
        width: auto;
        margin: 1rem 1rem;
    }

    .group-input-input{
      margin: 0;
    }

    input:focus{
      margin: 1rem 1rem;
    }
    .PhoneInput{
        width: 100%;
    }
    .checkbox{
        width: auto;
    }
    .error-message{
      left: 1rem;
      bottom: -.5rem;
    }
  }
  

  @media screen and (max-width:576px) {
     form{
        width: 100%;
     }
  }