.Steps{
    background-color: var(--secondary-color);
    padding: 2rem 0 5rem 0;
}

.steps{
    display: flex;
    flex-direction: column;
    align-items: center;
    counter-reset: step-counter;

}

.steps-item{
    display: flex;
    justify-content: space-between;
    width: 75%;
    padding: 2.5rem 0;
    counter-increment: step-counter;

}

.steps-item:nth-child(even) {
    flex-direction: row-reverse; 
  }

.steps-item .number{
    position: relative;
    width: 20%;
    font-size: 10rem;
    font-weight: 800;
    z-index: 0;
}



.steps-item .number::after{
    content: counter(step-counter);
    position: absolute;
    top: 5px;
    left: 5px;
    color: var(--primary-color);
    z-index: -1;
}

.step-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0 0 0 4rem;
    width: 70%;
}

.step-content h3{
    font-size: 2rem;
    padding: 1rem 0;
}

.step-content p{
    width: 80%;
    padding: .5rem 0;
}

@media screen and (max-width:992px) {
    .step-content{
        width: 70%;
        padding:unset;

    }
    .steps-item .number{
        width: 30%;
        font-size: 8rem;
    }
}

@media screen and (max-width: 576px) {
    .steps-item .number{
        font-size: 5rem;
    }
    .step-content h3{
        font-size: 1.5rem;
    }
    .step-content p{
        width: 100%;
    }
    
}