
.conditions{
    padding: 5rem 0;

}

.conditions ul{
    padding: 1rem 1rem;

}
.conditions h2{
    padding: 1rem 0 0 0;
}
.conditions, p{
    padding: .5rem 0;

}